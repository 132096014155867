// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NotFound_notFound__6-kto {
	text-align: center;
	padding: 150px 0px;
	height: 57vh;
}

.NotFound_notFound__6-kto h1 {
	font-size: 5rem;
	color: #ffa502;
}

.NotFound_notFound__6-kto p {
	font-size: 1.5rem;
	margin: 20px 0;
}

.NotFound_notFound__6-kto .NotFound_link__D0B5M {
	display: inline-block;
	margin-top: 20px;
	padding: 10px 20px;
	font-size: 1rem;
	color: #fff;
	background-color: #00304a;
	text-decoration: none;
	border-radius: 5px;
}

.NotFound_notFound__6-kto .NotFound_link__D0B5M:hover {
	background-color: #ffa502;
}
`, "",{"version":3,"sources":["webpack://./src/pages/NotFound/NotFound.module.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;CAClB,kBAAkB;CAClB,YAAY;AACb;;AAEA;CACC,eAAe;CACf,cAAc;AACf;;AAEA;CACC,iBAAiB;CACjB,cAAc;AACf;;AAEA;CACC,qBAAqB;CACrB,gBAAgB;CAChB,kBAAkB;CAClB,eAAe;CACf,WAAW;CACX,yBAAyB;CACzB,qBAAqB;CACrB,kBAAkB;AACnB;;AAEA;CACC,yBAAyB;AAC1B","sourcesContent":[".notFound {\n\ttext-align: center;\n\tpadding: 150px 0px;\n\theight: 57vh;\n}\n\n.notFound h1 {\n\tfont-size: 5rem;\n\tcolor: #ffa502;\n}\n\n.notFound p {\n\tfont-size: 1.5rem;\n\tmargin: 20px 0;\n}\n\n.notFound .link {\n\tdisplay: inline-block;\n\tmargin-top: 20px;\n\tpadding: 10px 20px;\n\tfont-size: 1rem;\n\tcolor: #fff;\n\tbackground-color: #00304a;\n\ttext-decoration: none;\n\tborder-radius: 5px;\n}\n\n.notFound .link:hover {\n\tbackground-color: #ffa502;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notFound": `NotFound_notFound__6-kto`,
	"link": `NotFound_link__D0B5M`
};
export default ___CSS_LOADER_EXPORT___;
